<!--
 * @Author: wch
 * @Date: 2020-07-30 18:19:58
 * @LastEditTime: 2021-03-05 11:06:30
 * @LastEditors: Please set LastEditors
 * @FilePath: \trunk\src\pages\equipmentState\berthStateStatistics\berthStateStatistics.vue
 * @Description: 实时统计
-->
<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div
        class="searchWrapper paddingLR20 paddingT20 marginB20"
        @keydown.enter.prevent="searchData"
      >
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          label-width="96px"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Road_name')">
                <my-component
                  ref="parkInput"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                  :commercialStatus="'2,3'"
                >
                </my-component>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <div class="circular" style="background: #f3f4fa">
        <el-row :gutter="20" class="list commonFa">
          <el-col :span="12">
            <div class="grid-content bg-purple graphShadow">
              <h2 style="text-align: center">
                当前泊位状态占比
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="当前正常泊位和异常泊位的占比"
                  placement="bottom-start"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </h2>
              <berthState :type="type" :berthOccupy="berthOccupy"></berthState>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content graphShadow">
              <h2 style="text-align: center">当前故障道路排名</h2>
              <faultPlate :faultPlate="faultPlate" :unit="'个'"></faultPlate>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="list commonFa" style="margin-top: 20px">
          <el-col :span="12">
            <div class="grid-content bg-purple graphShadow">
              <h2 style="text-align: center">
                上报故障类型占比
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="所有“未完工”工单的上报故障类型占比"
                  placement="bottom-start"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </h2>
              <berthState :type="type1" :memberCarNumber="memberCarNumber"></berthState>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import myComponent from "@/components/autocomplete/myautoComponent";
import berthState from "./berthState";
import faultPlate from "./faultPlate";
export default {
  components: { myComponent, berthState, faultPlate },
  data() {
    return {
      formInline: {
        parkId: "",
        parkName: "",
      },
      type: "c1",
      type1: "c2",
      berthOccupy: [],
      faultPlate: [],
      memberCarNumber: [],
      loading: false,
    };
  },
  // 方法集合
  methods: {
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    searchData() {
      this.countByState();
      this.countByFaultType();
      this.topNPark();
    },
    countByState() {
      let url = "/acb/2.0/aomEquOrder/countByBerthState";
      this.$axios
        .get(url, {
          data: {
            parentParkId: this.formInline.parkId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            console.log(res);
            this.berthOccupy = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    countByFaultType() {
      let url = "/acb/2.0/aomEquOrder/countByFaultType";
      this.$axios
        .get(url, {
          data: {
            parentParkId: this.formInline.parkId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.memberCarNumber = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    topNPark() {
      let url = "/acb/2.0/aomEquOrder/topNPark";
      this.$axios
        .get(url, {
          data: {
            parentParkId: this.formInline.parkId,
            size: 5,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.faultPlate = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  created() {},
  mounted() {
    this.searchData();
  },
};
</script>
<style lang="stylus" scoped>
// @import url(); 引入公共css类
.grid-content
  padding-top 10px
</style>
